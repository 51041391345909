.controls {
    @media (max-width: $mantine-breakpoint-xs) {
        flex-direction: column-reverse;
    }
}

.control {
    @media (max-width: $mantine-breakpoint-xs) {
        width: 100%;
        text-align: center;
    }
}

.pointer {
    cursor: pointer;
}

.paper {
    background-color: light-dark(var(--mantine-color-white), var(--mantine-color-dark-6));
}

.hidden {
    display: none;
}
.navbar {
    background-color: light-dark(var(--mantine-color-white), var(--mantine-color-dark-6));
    padding: var(--mantine-spacing-md);
    padding-bottom: 0;
    display: flex;
    flex-direction: column;
    border-right: rem(1px) solid light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4));
}

.header {
    height: rem(64px);
    display: flex;
    align-items: center;
    gap: rem(12px);
}

.navbarHeader {
    padding: var(--mantine-spacing-md);
    padding-top: 0;
    margin-left: calc(var(--mantine-spacing-md) * -1);
    margin-right: calc(var(--mantine-spacing-md) * -1);
    color: light-dark(var(--mantine-color-black), var(--mantine-color-white));
    border-bottom: rem(1px) solid light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4));
}

.links {
    flex: 1;
    margin-left: calc(var(--mantine-spacing-md) * -1);
    margin-right: calc(var(--mantine-spacing-md) * -1);
}

.linksInner {
    padding-top: var(--mantine-spacing-xl);
    padding-bottom: var(--mantine-spacing-xl);
}

.footer {
    margin-left: calc(var(--mantine-spacing-md) * -1);
    margin-right: calc(var(--mantine-spacing-md) * -1);
    border-top: rem(1px) solid light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4));
}

.main {
    @media (max-width: $mantine-breakpoint-xs) {
        padding-top: rem(64px);
    }
}
.container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.title {
    font-size: rem(26px);
    font-weight: 900;
    font-family: Greycliff CF, var(--mantine-font-family);
}
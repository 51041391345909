.th {
    padding: 0;
}

.control {
    width: 100%;
    padding: var(--mantine-spacing-xs) var(--mantine-spacing-md);

}

.icon {
    width: rem(21px);
    height: rem(21px);
    border-radius: rem(21px);
}

.noWrapText {
    > td {
        white-space: nowrap;
        p {
            white-space: nowrap;
        }
    }
}